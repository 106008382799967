import React from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import { AvaAuth } from "@api/auth";
import theme from "~/styles/theme";

export { ToastProvider, useToastContext } from "./toast";
import { ViewContextProvider, useViewContext } from "./view";
import { FeedbackProvider } from "~/components/Feedback";

export { ViewContextProvider, useViewContext };

export const wrapWithProviders = (element: JSX.Element) => (
	<ThemeProvider theme={theme}>
		<AvaAuth.Provider>
			<ViewContextProvider>
				<FeedbackProvider>{element}</FeedbackProvider>
			</ViewContextProvider>
		</AvaAuth.Provider>
	</ThemeProvider>
);

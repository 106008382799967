export { normalizeUrl } from "@autocorp/web-core-utils/lib/normalizeUrl";

interface IParsedQuery {
	[key: string]: string | number;
}

/** Returns an url string with a trailing slash */
export function slash(to: string): string {
	if (!to) return to;
	if (to.endsWith("/")) return to;
	return to + "/";
}

export function parseQuery(
	queryString: string,
	parseVal = false,
): IParsedQuery {
	return (queryString[0] === "?" ? queryString.slice(1) : queryString)
		.split("&")
		.reduce((acc, pair) => {
			const [key = "", val = ""] = pair
				.split("=")
				.map((pairVal) => decodeURIComponent(pairVal || ""));

			if (!key) return acc;

			if (parseVal) {
				const parsed = parseInt(val, 10);
				acc[key] = isNaN(parsed) ? val : parsed;
			} else {
				acc[key] = val;
			}
			return acc;
		}, {} as IParsedQuery);
}

export function stringifyQuery(
	query: Record<string, any> = {},
	filterEmpty = false,
	persistExisting = false,
): string {
	return Object.entries({
		...(persistExisting ? parseQuery(location?.search) : {}),
		...query,
	})
		.reduce((acc, [key, val]) => {
			const newKey = encodeURIComponent(key);
			const newVal = encodeURIComponent(val);
			if ((filterEmpty && newKey && newVal) || !filterEmpty) {
				return acc.concat([[newKey, newVal].filter(Boolean).join("=")]);
			}
			return acc;
		}, [] as string[])
		.join("&");
}

import { ApolloError, ServerError, ServerParseError } from "@apollo/client";
import { serializeError as _serializeError } from "serialize-error";
import { arrayToObject } from "@utils/obj";

import type { GraphQLError } from "graphql";

interface IStandardError {
	message: string;
	networkError?: Record<string, any>;
}

type NetworkError = ApolloError["networkError"];

type BaseErrorType = GraphQLError | Error | IStandardError | string | number;
export type ErrorType =
	| ApolloError
	| BaseErrorType
	| BaseErrorType[]
	| ReadonlyArray<BaseErrorType>;

const isParseError = (input: NetworkError): input is ServerParseError =>
	!!(input as ServerParseError)?.bodyText;
const isServerError = (input: NetworkError): input is ServerError =>
	!!(input as ServerError)?.result;

export const getError = (val?: ErrorType): BaseErrorType | undefined => {
	if (val instanceof ApolloError) {
		if (val.graphQLErrors.length > 0) return getError(val.graphQLErrors);

		if (isServerError(val.networkError)) {
			const result = val.networkError.result as Record<string, any>;
			const errors = result.errors;
			if (errors && errors.length > 0)
				return getError(result.errors);
		}
	}
	return Array.isArray(val)
		? (arrayToObject(val) as unknown as BaseErrorType)
		: (val as BaseErrorType);
};

const serializeObj = (obj: any): any => {
	if (obj == null) return obj;
	if (Array.isArray(obj)) {
		return serializeObj(arrayToObject(obj));
	}

	if (typeof obj === "object") {
		return Object.entries(obj).reduce((acc, [key, val]) => {
			return Object.assign(acc, {
				[key]: serializeObj(val),
			});
		}, {} as Record<string, unknown>);
	}

	return obj;
};
export const serializeError = (
	err?: ErrorType,
): Record<string, unknown> | undefined => {
	if (!err) return;

	const obj = _serializeError(err);
	return serializeObj(obj);
};
export const stringifyError = (err: ErrorType): string =>
	JSON.stringify(serializeError(getError(err)));

const isArray = (
	val?: any | any[] | ReadonlyArray<any>,
): val is any[] | ReadonlyArray<any> => Array.isArray(val);

export const getErrorMessage = (val?: ErrorType): string => {
	// const chkErr = getError(val);
	switch (typeof val) {
		case "string": {
			return val;
		}
		case "object": {
			if (isArray(val)) {
				return getErrorMessage(val[0]);
			}
			if (val.message) {
				return val.message;
			}
			return stringifyError(val);
		}
		default: {
			if (!val) return "";
			if (typeof val !== "string" && val != null) {
				return val.toString();
			}
			return val;
		}
	}
};

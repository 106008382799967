import React, { useRef } from "react";
import { SnackbarProvider } from "notistack";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(({ breakpoints }) => ({
	toastMessageContainer: {
		maxWidth: "90%",
		[breakpoints.up(breakpoints.values.md)]: {
			maxWidth: "50%",
		},
		[breakpoints.up(breakpoints.values.lg)]: {
			maxWidth: "33%",
		},
	},
	toastRoot: {
		display: "flex",
		flexFlow: "row nowrap",
	},
}));

export const ToastProvider: React.FC = ({ children }) => {
	const notistackRef = useRef<SnackbarProvider>(null);
	const styles = useStyles();

	return (
		<SnackbarProvider
			ref={notistackRef}
			dense
			maxSnack={10}
			classes={{
				containerRoot: styles.toastMessageContainer,
				// root: styles.toastRoot,
			}}
			className={styles.toastRoot}
		>
			{children}
		</SnackbarProvider>
	);
};

module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-mdx@2.15.0_@mdx-js+mdx@1.6.22_@mdx-js+react@1.6.22_gatsby@3.13.1_react-dom@16.14.0_react@16.14.0/node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx"],"defaultLayouts":{},"gatsbyRemarkPlugins":[],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/opt/build/repo/apps/ac-dealer-portal","JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-google-tagmanager@3.15.0_gatsby@3.13.1_react-dom@16.14.0_react@16.14.0/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-KH6WL95","defaultDataLayer":{"type":"object","value":{"userName":""}},"includeInDevelopment":false,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-material-ui@3.0.1_@babel+core@7.20.5_@material-ui+styles@4.11.5_core-js@3.26.1_gatsby@3.13.1/node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    }]

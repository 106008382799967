import React, { useState, useContext, createContext } from "react";

import { AlertProps } from "@material-ui/lab/Alert";
import { Feedback } from ".";

interface FeedbackProps {
	message: string;
	severity: AlertProps["severity"];
}

type FeedbackContextType = {
	openFeedbackMessage: FeedbackProps;
	handleOpenFeedback: (props: FeedbackProps) => void;
	handleCloseFeedback: () => void;
};

const success = "success" as AlertProps["severity"];

const defaultContext: FeedbackContextType = {
	openFeedbackMessage: { message: "", severity: success },
	handleOpenFeedback: (props) => {
		/* dummy Fb */
	},
	handleCloseFeedback: () => {
		/* dummy Fb */
	},
};

const FeedbackContext = createContext<FeedbackContextType>(defaultContext);

export const useFeedbackContext = () => {
	const context = useContext(FeedbackContext);
	if (!context) {
		throw new Error(
			"You cannot use FeedbackContext from outside of its Provider",
		);
	}

	return context;
};

const useFeedbackContextValue = () => {
	const [openFeedbackMessage, setOpenFeedbackMessage] = useState({
		message: "",
		severity: success,
	});

	const handleOpenFeedback = (props: FeedbackProps) => {
		setOpenFeedbackMessage(props);
	};

	const handleCloseFeedback = () => {
		setOpenFeedbackMessage({ message: "", severity: success });
	};

	return {
		...defaultContext,
		openFeedbackMessage,
		handleOpenFeedback,
		handleCloseFeedback,
	};
};

export interface IFeedbackProviderProps {}

export const FeedbackProvider: React.FC<IFeedbackProviderProps> = ({
	children,
}) => {
	const contextValue = useFeedbackContextValue();

	return (
		<FeedbackContext.Provider value={contextValue}>
			{children}
			<Feedback />
		</FeedbackContext.Provider>
	);
};

import {
	createHttpLink,
	InMemoryCache,
	NormalizedCacheObject,
	ApolloClient,
	ApolloLink,
	DocumentNode,
	OperationVariables,
	from,
	defaultDataIdFromObject,
	NormalizedCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { mergeDeep } from "@apollo/client/utilities";

import sortBy from "lodash/sortBy";
import isEqual from "lodash/isEqual";

import { reduceVariableSet } from "./graphql-utils";
import {
	makeProcessedFieldsMerger,
	storeValueIsStoreObject,
} from "@apollo/client/cache/inmemory/helpers";
import type { AvaAuth } from "../auth";

export { mergeDeep, makeProcessedFieldsMerger, storeValueIsStoreObject };

export type GraphqlClient = ApolloClient<NormalizedCacheObject>;
export type { NormalizedCache };

const IMPERSONATE_HEADER = "x-dealer-view-cognito-groups";
const REQUEST_ID_HEADER = "x-request-id";

type RestrictSettings = {
	impersonate?: string;
	restrictView?: boolean;
};

type RestrictedQueryMap = Map<string, Map<string, RestrictSettings>>;

const normalizeVariables = (variables?: OperationVariables) => {
	return JSON.stringify(
		sortBy(Object.entries(variables || {}), (a) => a[0]).reduce(
			(acc, [key, val]) =>
				Object.assign(acc, {
					[key]: val,
				}),
			{} as Record<string, string>,
		),
	);
};

/**
 * Do not instantiate an instance of this directly.  To access the `Client` instance, use
 * `AvaAuth.client`.
 */
export class Client {
	public static StandardHeaders = {} as Record<string, string>;

	private _client: GraphqlClient;
	private restrictedQueries: RestrictedQueryMap = new Map();

	public static getInstance(auth: AvaAuth): Client {
		return new Client(auth);
	}

	constructor(private auth: AvaAuth) {
		const authLink = setContext(async (operation, context) => {
			const creds = await auth.getJwtToken(true);

			return Object.assign(context || {}, {
				headers: Object.assign(
					context?.headers || {},
					Client.StandardHeaders,
					creds && {
						authorization: `Bearer ${creds}`,
					},
				),
			});
		});

		const restrictedQueryLink = setContext(async (operation, context) => {
			if (
				context.hasOwnProperty("restrict") &&
				this.auth?.canImpersonate
			) {
				this.updateRestrictedQueryCache(
					{
						impersonate: context?.impersonate,
						restrictView: context?.restrict,
					},
					operation.query,
					operation.variables,
				);

				const addImpersonationHeader = !!(
					context &&
					context.restrict &&
					context.impersonate
				);
				const headers = Object.assign(
					context?.headers || {},
					!addImpersonationHeader
						? {}
						: {
								[IMPERSONATE_HEADER]: context?.impersonate,
						  },
				);

				const forceFetch = context?.refetch
					? true
					: context?.forceFetch;

				return Object.assign(context || {}, { headers, forceFetch });
			}

			return context;
		});

		const requestTracker = new ApolloLink((operation, forward) => {
			return forward(operation).map((response) => {
				const context = operation.getContext();
				const {
					response: { headers },
				} = context || {};

				if (headers) {
					const requestId = headers.get(REQUEST_ID_HEADER);

					if (requestId) {
						Client.StandardHeaders[REQUEST_ID_HEADER] = requestId;
					}
				}

				return response;
			});
		});

		const httpLink = createHttpLink({
			uri: process.env.GATSBY_API_URL,
		});

		const cache = this.getCache();

		this._client = new ApolloClient({
			connectToDevTools: true,
			cache,
			link: from([
				authLink,
				restrictedQueryLink,
				requestTracker,
				httpLink,
			]),
			ssrMode: typeof window === "undefined",
		});
	}

	private getCache() {
		return new InMemoryCache({
			addTypename: true,
			typePolicies: {
				CreditBureau: {
					keyFields: false,
				},
				CreditReport: {
					keyFields: false,
				},
				Company: {
					fields: {
						meta: {
							merge: true,
						},
					},
				},
				GroupMembership: {
					fields: {
						users: {
							// Appease the Apollo god.  The Array merging is handled by the
							// users api hook, since it has more context (i.e. it knows what
							// action is being performed).
							merge(existing, incoming, options) {
								return incoming;
							},
						},
					},
				},
				UserDetails: {
					keyFields: false,
					merge: true,
				},
			},
			dataIdFromObject: (object, context) => {
				const defaultDataId = defaultDataIdFromObject(object, context);
				const chkType = (object?.__typename || "").toLowerCase();
				const prefix = ["company", "lead"];
				const suffix = ["meta"];

				const isMeta =
					prefix.find((v) => chkType.startsWith(v)) &&
					suffix.find((v) => chkType.endsWith(v));

				if (isMeta) {
					return false;
				}

				// always use default if not meta column
				return defaultDataId;
			},
		});
	}

	public setAuth(auth: AvaAuth) {
		this.auth = auth;
	}

	public clearCache() {
		this.client.clearStore();
	}

	public updateRestrictedQueryCache = (
		restrictSettings: RestrictSettings,
		query?: DocumentNode,
		variables?: OperationVariables,
	) => {
		if (!query) return;

		query.definitions.forEach((defs) => {
			if (defs.kind !== "OperationDefinition") return;

			defs.selectionSet?.selections?.forEach((selection) => {
				if (selection.kind !== "Field") return;

				const fieldName = selection.name.value;
				if (!fieldName) return;

				if (!this.restrictedQueries.has(fieldName)) {
					this.restrictedQueries.set(fieldName, new Map());
				}

				const variableSet = reduceVariableSet(
					selection.arguments || [],
					variables,
				);

				this.restrictedQueries
					.get(fieldName)!
					.set(normalizeVariables(variableSet), restrictSettings);
			});
		});
	};

	public evictRestrictedQueries = (restrictSettings: RestrictSettings) => {
		const cache = this._client.cache;

		for (const [
			fieldName,
			variableSets,
		] of this.restrictedQueries.entries()) {
			for (const [variableSetKey, settings] of variableSets.entries()) {
				if (!isEqual(settings, restrictSettings)) {
					cache.evict({
						fieldName,
						args: JSON.parse(variableSetKey),
					});
					variableSets.delete(variableSetKey);
				}
			}

			if (variableSets.size === 0) {
				this.restrictedQueries.delete(fieldName);
			}
		}
	};

	public get client(): GraphqlClient {
		return this._client;
	}
}

import React, { useMemo } from "react";
import {
	Redirect as BaseRedirect,
	IRedirectProps,
} from "~/components/Redirect";
import { useNavigate } from "~/hooks/use-navigate";
import { prefetch } from "@utils/prefetch";
import { isElement } from "react-is";

import type { ReactElement } from "react";

import type { LayoutModel, GetLayoutModelProps } from "~/model/layout";

import type {
	PageProps,
	ViewModelProps,
	ViewComponent,
	ViewComponentProps,
	ViewWrapper,
	GetTitle,
	GetRedirect,
	ICreateViewArgs,
} from "./types";

export type ViewModelMeta<
	TComponent extends ViewComponent<any> = ViewComponent<any>,
	TLayout extends LayoutModel = LayoutModel,
> = {
	layout: TLayout;
	layoutProps?: GetLayoutModelProps<TLayout>;

	viewWrapper?: ViewWrapper<TComponent>;

	showBack: boolean;
	backTo?: string;

	internalScroll: boolean;
	internalPad: boolean;
};

export const ViewTypeSymbol = Symbol("View");
type FirstClassMeta = ViewModelMeta & {
	$$typeof: typeof ViewTypeSymbol;
	displayName: string;
	hasToast: boolean;
	getTitle: GetTitle;
	getRedirect: GetRedirect;
};

const isReactElement = (
	input: any,
): input is ReactElement<ViewComponentProps, ViewModel> =>
	isElement(input) && !!input.type;

export const isViewComponent = (element: any): element is ViewComponent => {
	return (
		isReactElement(element) &&
		typeof element.type === "function" &&
		element.type["$$typeof"] === ViewTypeSymbol
	);
};

export type ViewModel = React.FC<ViewModelProps> & FirstClassMeta;

export const getViewModel = <
	TContext extends Record<string, unknown> = Record<string, unknown>,
	TComponent extends ViewComponent<any> = ViewComponent<any>,
>(
	component: TComponent,
	meta: ViewModelMeta,
	{
		title,
		redirectTo,
		redirectWithAuth,
		layout,
	}: ICreateViewArgs<TContext, TComponent>,
): ViewModel => {
	const context = {} as TContext;

	const resolveTitle = (location: PageProps["location"]) => {
		if (!title) return "";
		return typeof title === "function"
			? title(location)
			: location.state?.title ?? title;
	};

	const getTitle = (location: PageProps["location"]) => {
		return resolveTitle(location);
	};

	const getRedirect: GetRedirect = (props) => {
		if (!redirectWithAuth) return null;
		const Redirect = (redirProps: IRedirectProps) => (
			<BaseRedirect {...redirProps} location={props.location} />
		);
		if (redirectTo) prefetch(redirectTo);
		prefetch(props.location.pathname);
		return redirectWithAuth(
			{
				...props,
				Redirect,
			},
			context,
		);
	};

	return Object.assign<ViewModel, FirstClassMeta>(
		function ViewModel(props: ViewModelProps) {
			const ViewComponent = component as ViewComponent;
			const location = props.location;

			const navigate = useNavigate(location);

			const componentProps = useMemo<ViewComponentProps>(
				() => ({
					...props,
					navigate,
				}),
				[props, navigate],
			);

			return <ViewComponent {...componentProps} />;
		} as ViewModel,
		{
			...meta,
			$$typeof: ViewTypeSymbol,
			displayName: typeof title === "string" ? title : "ViewModel",
			hasToast: layout?.hasToast ?? false,
			getTitle,
			getRedirect,
		},
	);
};

import React from "react";

import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";

import { useFeedbackContext, FeedbackProvider } from "./context";

const Alert = (props: AlertProps) => {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const Feedback = () => {
	const { openFeedbackMessage, handleCloseFeedback } = useFeedbackContext();

	const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
		if (reason === "clickaway") {
			return;
		}
		handleCloseFeedback();
	};

	return (
		<Snackbar
			anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
			open={!!openFeedbackMessage.message}
			autoHideDuration={3000}
			onClose={handleClose}
		>
			<Alert
				onClose={handleClose}
				severity={openFeedbackMessage.severity ?? "success"}
			>
				{openFeedbackMessage.message}
			</Alert>
		</Snackbar>
	);
};

export { Feedback, useFeedbackContext, FeedbackProvider };

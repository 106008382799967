import yn from "yn";
import { ReadyPromise } from "@autocorp/web-core-utils/lib/ReadyPromise";

export const PENDO_READY = "__pendoReady";
export const WITH_PENDO = yn(process.env.GATSBY_WITH_PENDO, { default: false });
export const mpReadyPromise = new ReadyPromise();
export const usePendo = WITH_PENDO;

// if (!usePendo) mpReadyPromise.cancel();

interface Pendo {
	initialize: (props: PendoProps) => void;
}

declare global {
	interface Global {
		pendo?: Pendo;
	}
	interface Window {
		pendo?: Pendo;
		[PENDO_READY]?: VoidFunction | true;
	}
}

export type PendoEventOptions = any;
type DealerPortalAppContext = {
	dealerId?: number;
	userEmail: string;
};

const getPendoInstance = () => {
	return (window.pendo = window.pendo || ({} as Pendo));
};

export interface PendoProps {
	visitor: {
		id: string;
		email: string;
		full_name: string;
		role?: string;
	};

	account: {
		id: string;
		name: string;
		is_paying?: boolean;
		monthly_value?: string;
		planLevel?: string;
		planPrice?: string;
		creationDate?: string;
	};
}

/**
 * Registers/attaches a set of super properties with every event sent to Pendo
 * @param properties An associative array of properties to store with each fired event
 */
const register = (props: PendoProps) => {
	const pendoInst = getPendoInstance();

	if (!usePendo) {
		return;
	}

	console.log("Register pendo:", props);
	pendoInst.initialize(props);
};

// /**
//  * Fires an event to be tracked in Pendo
//  * @param eventOptions Options that include the name of the event itself and any context variables that may be required
//  * @param appContext Dealer Portal app context which consists of `dealerId` and `userEmail`
//  */
// const fireEvent = (
//     eventOptions: PendoEventOptions,
//     appContext?: DealerPortalAppContext,
// ) => {
//     const pendoInst = getPendoInstance();
//     mpReadyPromise.then(() => {
//         pendoInst.track(eventOptions.event, (eventOptions as unknown as Record<"context", any>).context);
//         if (appContext) pendoInst.register(appContext);
//     });
// };

export const pendo = { register };

import gql from "graphql-tag";

export const GetUserMeta = gql`
	query GetUserMeta($id: Int) {
		companyDetails(getCompanyId: $id) {
			nodes {
				id
				name
				legalConsent
				primaryGroup
				companyProducts {
					nodes {
						product {
							name
							enabled
						}
					}
				}
				groupByPrimaryGroup {
					id
					cognitoName
					cognitoGroup
					name
					type
				}
				archived
				rawMeta
				widgets(first: 1) {
					nodes {
						id
						ctaTheme
					}
				}
			}
		}
		currentUserPermissions {
			autocorp
			companies {
				id
				name
				permissions
			}
		}
	}
`;

export const UserDetailsFragment = gql`
	fragment UserDetailsFragment on UserDetails {
		groupId
		nodeId
		email
		role
		status
		username: email
		lastLogin
		firstName
		lastName
	}
`;

export const GroupDetailsFragment = gql`
	fragment GroupMembershipDetail on GroupMembership {
		id
		name
		type
		users {
			...UserDetailsFragment
		}
	}
	${UserDetailsFragment}
`;

export const GetUserList = gql`
	query GetUserList($groupId: Int) {
		listUsers(groupId: $groupId) {
			groups {
				...GroupMembershipDetail
			}
		}
	}
	${GroupDetailsFragment}
`;

export const CreateUser = gql`
	mutation CreateUser($input: CreateUserInput!) {
		createUser(input: $input) {
			success
			temporaryPassword
			user {
				...UserDetailsFragment
			}
		}
	}
	${UserDetailsFragment}
`;

export const RemoveUser = gql`
	mutation RemoveUser($username: String!) {
		removeUser(input: { username: $username }) {
			success
		}
	}
`;

export const GrantUserAccess = gql`
	mutation GrantUserAccess($input: GiveUserAccessInput!) {
		giveUserAccess(input: $input) {
			success
		}
	}
`;

export const RevokeUserAccess = gql`
	mutation RevokeUserAccess($input: RevokeUserAccessInput!) {
		revokeAccess(input: $input) {
			success
		}
	}
`;

export const ResetUserPassword = gql`
	mutation ResetUserPassword($username: String!) {
		resetUserPassword(username: $username) {
			success
			temporaryPassword
		}
	}
`;

export const GetUserActivity = gql`
	query GetUserActivity(
		$filter: UserActivityFilter
		$first: Int!
		$offset: Int!
	) {
		userActivities(
			filter: $filter
			first: $first
			offset: $offset
			orderBy: CREATED_AT_DESC
		) {
			nodes {
				claims
				createdAt
				username
				type
				meta
			}
			pageInfo {
				startCursor
				endCursor
				hasPreviousPage
				hasNextPage
			}
			totalCount
		}
	}
`;

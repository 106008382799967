import Sentry from "@autocorp/gatsby-plugin-sentry";
import { getToken } from "@api/auth";
import { serializeError } from "@utils/errors";
import { getError, getErrorMessage, ErrorType } from "./errors";
import { ApolloError } from "@apollo/client";

const Severity = Sentry.Severity;
const captureMessage = Sentry.captureMessage;
const captureException = (
	exception: any,
	extra?: Record<string, unknown>,
): void => {
	const token = getToken();
	Sentry.captureException(exception, {
		extra: {
			user: token?.email,
			...extra,
		},
	});
};

interface ICaptureErrorContext {
	error?: ErrorType;
	extra?: Record<string, unknown>;
}
export const captureError = (
	errorOrMessage: ErrorType,
	{ error = errorOrMessage, extra = {} }: ICaptureErrorContext,
) => {
	const token = getToken();
	const extractedError = getError(error);
	const message = getErrorMessage(errorOrMessage);

	const exception =
		extractedError instanceof Error ? extractedError : new Error(message);

	const graphqlErrors = serializeError(
		errorOrMessage instanceof ApolloError &&
			errorOrMessage.graphQLErrors?.length > 0
			? errorOrMessage.graphQLErrors
			: undefined,
	);

	Sentry.captureException(exception, {
		extra: {
			message,
			token,
			user: token?.email,
			originalError: serializeError(error),
			graphqlErrors,
			...extra,
		},
	});
};
const captureEvent = Sentry.captureEvent;

export {
	Sentry,
	Severity,
	captureMessage,
	captureException,
	captureEvent,
	getErrorMessage,
};

import { isSsr } from "./ssr";

declare global {
	type GtmEvent = "logged_in" | "logged_out";
	interface IGtmDataLayerVariables {
		userName?: string;
		[variable: string]: string | undefined;
	}
	interface IGtmDataLayerEvent extends IGtmDataLayerVariables {
		event: GtmEvent;
	}

	type GtmDataLayer = (IGtmDataLayerVariables | IGtmDataLayerEvent)[];

	interface Window {
		dataLayer: GtmDataLayer;
	}
}

const dataLayer = isSsr() ? [] : (window.dataLayer = window.dataLayer || []);

export const fireLogin = (userName: string) => {
	dataLayer.push({
		event: "logged_in",
		userName,
	});
};
export const fireLogout = () => {
	// Keep the user name variable set in the data layer until after
	// the logged_out event is fired
	dataLayer.push({
		event: "logged_out",
	});
	dataLayer.push({
		userName: undefined,
	});
};

import type { OperationVariables } from "@apollo/client";
import type { ArgumentNode, ObjectFieldNode, ValueNode } from "graphql";

const getValueNode = (node: ValueNode, variables: OperationVariables) => {
	let argValue: any;
	switch (node.kind) {
		case "Variable": {
			argValue = (variables || {})[node.name.value];
			break;
		}
		case "IntValue": {
			argValue = JSON.parse(node.value);
			break;
		}
		case "FloatValue": {
			argValue = parseFloat(node.value);
			break;
		}
		case "NullValue": {
			argValue = null;
			break;
		}
		case "ListValue": {
			argValue = node.values.map((idx) => getValueNode(idx, variables));
			break;
		}
		case "ObjectValue": {
			argValue = reduceVariableSet(node.fields, variables);
			break;
		}
		case "StringValue":
		case "EnumValue":
		case "BooleanValue": {
			argValue = node.value;
		}
	}

	return argValue;
};

const getVariableSet = (
	node: ArgumentNode | ObjectFieldNode,
	variables = {} as OperationVariables,
) => ({
	name: node.name.value,
	value: getValueNode(node.value, variables),
});

export const reduceVariableSet = (
	nodes: readonly (ArgumentNode | ObjectFieldNode)[],
	variables = {} as OperationVariables,
) => {
	return nodes.reduce((acc, node) => {
		const { name, value } = getVariableSet(node, variables);
		return Object.assign(acc, {
			[name]: value,
		});
	}, {} as Record<string, any>);
};

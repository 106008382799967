import React, { ReactElement } from "react";
import { navigate } from "@utils/navigate";

import type { PageProps, ILocationState } from "~/model/view";

export interface IRedirectProps {
	to: string;
	state?: ILocationState;
	saveState?: boolean;
	saveFrom?: boolean;
	location?: PageProps["location"];
	returnTo?: boolean;
	Render?: ReactElement;
}

export type RedirectComponent = React.FC<IRedirectProps>;

export const Redirect: RedirectComponent = ({
	to,
	state,
	location,
	returnTo,
	saveFrom,
	saveState = true,
	Render = null,
}) => {
	navigate(
		to,
		{
			state,
			saveState: !returnTo && saveState,
			savePath: saveFrom,
			back: returnTo,
		},
		location,
	);

	return Render;
};

import {
	createActionHandlers,
	createContext,
} from "@autocorp/react-core/context-reducer";

import type { IPublicAppDrawerProps } from "~/components/Modal";
import type { ReactNode } from "react";

export interface IViewState {
	modal: {
		content: ReactNode | null;
		props: IPublicAppDrawerProps;
	};
	/** The display value for the title in the Dealer Portal layout. */
	title: string | JSX.Element;
	/** Provisional state to manage income verification. */
	incomeVerified: boolean;
}

const defaultViewState: IViewState = {
	modal: {
		content: null,
		props: {},
	},
	title: "",
	incomeVerified: false,
};

const viewContextActions = createActionHandlers(defaultViewState, {
	openModal: (input: Partial<IViewState["modal"]>) => {
		const newModal: IViewState["modal"] = {
			content: null,
			props: {},
		};
		if ("content" in input) {
			newModal.content = input.content!;
			newModal.props = input.props!;
		} else if ("props" in input) {
			newModal.props = input.props!;
		}

		return {
			modal: newModal,
		};
	},
	closeModal: (_: null) => {
		return {
			modal: {
				content: null,
				props: {},
			},
		};
	},
	updateTitle: (input: IViewState["title"], state) => {
		const newTitleState = input;
		return {
			...state,
			title: newTitleState,
		};
	},
	updateIncomeVerification: (
		payload: IViewState["incomeVerified"],
		state,
	) => {
		return {
			...state,
			incomeVerified: payload,
		};
	},
});

const generateNewViewContext = (name: string) => {
	return createContext(name, defaultViewState, viewContextActions);
};

const [ViewContextProvider, useViewContext] =
	generateNewViewContext("ViewContext");

export { ViewContextProvider, useViewContext, generateNewViewContext };

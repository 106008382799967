import { createMdxBaseRoutes } from "@autocorp/gatsby-source-askava";

export const dateFormat = "DD/MM/YYYY";
export const dtFormat = "DD/MM/YYYY HH:mma";
export const internalDateFormat = "YYYY-MM-DD";
export const iso8601offset = "YYYY-MM-DDTHH:mm:ssZ";
export const defaultToastTimeout = 5000;

export const mdxBaseRoutes = createMdxBaseRoutes({
	categories: {
		"Install Guide": {
			base: "guide",
			subCategories: {
				Credit: {
					base: "credit-tool",
					query: {
						product: "creditTool",
					},
				},
				Trade: {
					base: "trade-tool",
					query: {
						product: "tradeTool",
					},
				},
				MSA: {
					base: "msa-tool",
					query: {
						product: "msaTool",
					},
				},
				Drive: {
					base: "drive-tool",
					query: {
						product: "driveTool",
					},
				},
			},
		},
		API: {
			base: "api",
			subCategories: {
				"CTA/Modal": {
					base: "modal-api",
				},
			},
		},
		Marketing: {
			base: "marketing",
			subCategories: {
				Gallery: {
					base: "gallery",
					// query: {
					//     product: "creditTool",
					// },
				},
				"Client API Docs": {
					base: "client-api-docs",
				},
				Analytics: {
					base: "analytics",
				},
			},
		},
		/*
        "CTA": {
            base: "cta",
            subCategories: {
                "AVA Credit": "credit-tool",
                "AVA Trade": "trade-tool",
            },
        },
        */
	},
});

import { useCallback } from "react";
import type { WindowLocation } from "~/model/view";
import { navigate, IComponentNavigateFn } from "@utils/navigate";

export const useNavigate = (location: WindowLocation) => {
	return useCallback<IComponentNavigateFn>(
		(...args) => navigate(...args, location),
		[location],
	);
};

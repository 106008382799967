/* eslint-disable react-hooks/rules-of-hooks */
import React, { useMemo } from "react";
import merge from "lodash/merge";
import { Placeholder } from "~/components/Placeholder";
import type { PageProps, ViewComponentProps, ViewWrapper } from "~/model/view";
import { useNavigate } from "~/hooks/use-navigate";
import type { IComponentNavigateFn } from "@utils/navigate";

export type BaseProp = Record<string, unknown>;

export type BaseLayoutProps<
	TLayoutProps = BaseProp,
	TPageQueryData = BaseProp,
	TBuildContext = BaseProp,
	TPageProps = BaseProp,
> = {
	navigate: IComponentNavigateFn;
	location: PageProps["location"];
	pageProps: ViewComponentProps<TPageQueryData, TBuildContext> & TPageProps;

	title: string | JSX.Element;
	showBack: boolean;
	backTo?: string;
	internalScroll?: boolean;
	internalPad?: boolean;

	viewWrapper?: ViewWrapper;

	layoutProps?: TLayoutProps;
};
export type LayoutComponent<
	TLayoutProps = BaseProp,
	TPageQueryData = BaseProp,
	TBuildContext = BaseProp,
	TPageProps = BaseProp,
> = React.FC<
	BaseLayoutProps<TLayoutProps, TPageQueryData, TBuildContext, TPageProps>
>;
export type AnyLayoutComponent = LayoutComponent<any, any, any, any>;

type InternalLayoutComponent<
	P = BaseProp,
	D = BaseProp,
	C = BaseProp,
> = React.FC<OmitKeys<BaseLayoutProps<P, D, C>, "navigate">>;

type GetLayoutProps<T extends AnyLayoutComponent> = T extends LayoutComponent<
	infer P
>
	? P
	: never;

export type GetLayoutModelProps<T extends LayoutModel> = T extends LayoutModel<
	infer C
>
	? C extends AnyLayoutComponent
		? GetLayoutProps<C>
		: never
	: never;

type LayoutComponentProps<T extends AnyLayoutComponent> = GetLayoutProps<T>;

type And<T1, V1, T2, V2> = T1 extends V1
	? T2 extends V2
		? "true"
		: "false"
	: "false";

type ExtendIfNotSymbol<T, O> = O extends symbol ? T : T & O;
type LayoutModelProps<
	TLayoutComponent extends AnyLayoutComponent | undefined | void,
> = ExtendIfNotSymbol<
	{
		render?: TLayoutComponent;
		hasToast?: boolean;
	},
	TLayoutComponent extends LayoutComponent<infer P>
		? P extends BaseProp
			? P extends undefined | void
				? { props?: P }
				: { props: P }
			: symbol
		: symbol
>;

export class LayoutModel<
	TLayoutComponent extends
		| AnyLayoutComponent
		| undefined
		| void = AnyLayoutComponent,
> {
	private readonly layout: LayoutComponent<any>;
	private readonly layoutProps: LayoutComponentProps<LayoutComponent<any>>;
	public readonly hasToast: boolean;

	constructor(opts = {} as LayoutModelProps<TLayoutComponent>) {
		const {
			render = Placeholder,
			props = {},
			hasToast = true,
		} = opts as unknown as LayoutModelProps<LayoutComponent<BaseProp>>;

		this.layout = render;
		this.layoutProps = props;
		this.hasToast = hasToast;
	}

	public Render: InternalLayoutComponent = ({
		children,
		layoutProps: newLayoutProps = {},
		...props
	}) => {
		const Layout = this.layout;
		const layoutProps = useMemo(
			() => merge({}, this.layoutProps, newLayoutProps),
			[newLayoutProps],
		);

		const navigate = useNavigate(props.location);

		return (
			<Layout {...props} navigate={navigate} layoutProps={layoutProps}>
				{children}
			</Layout>
		);
	};
}
